import * as React from "react"

import shape2 from "../../images/reviews/reviews-shape-2.png"

import userImg1 from "../../images/reviews/reviews-7.png"
import userImg2 from "../../images/reviews/reviews-8.png"
import userImg3 from "../../images/reviews/reviews-9.png"
import userImg4 from "../../images/reviews/reviews-10.png"
import userImg5 from "../../images/reviews/reviews-11.png"
import userImg6 from "../../images/reviews/reviews-12.png"
import userImg7 from "../../images/reviews/reviews-13.png"
import userImg8 from "../../images/reviews/reviews-14.png"
import userImg9 from "../../images/reviews/reviews-15.png"

const OurUser = () => {
  return (
    <>
      <div id="user" className="currecy-user-area  bg-color-0e1013 ptb-100">
        <div className="container">
          <div className="currency-section-title">
            <span>OUR USER</span>
            <h2>User From All Over The World</h2>
          </div>

          <div className="currecy-user-content">
            <img src={shape2} alt="Image" />
            <ul className="currency-user-list">
              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg1}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>Business Developer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg2}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>React Developer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg3}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>Angular developer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg4}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>Qa Engineer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg5}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>WP Developer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg6}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>UI/UX Designer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg7}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>Vue.js Developer</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg8}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>Marketing Manager</span>
                  </div>
                </div>
              </li>

              <li>
                <div className="currency-user-card">
                  <div className="user-img">
                    <img
                      src={userImg9}
                      alt="Image"
                    />
                  </div>
                  <div className="user-content">
                    <div className="rating-list">
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                      <i className="flaticon-star"></i>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet con adipiscing elit susp
                      endisse.{" "}
                    </p>
                    <h3>Evgeny Gerasim</h3>
                    <span>SEO Specialist</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurUser
